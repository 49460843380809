import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Home, ShoppingBag, Info, Lock, Phone, Eye } from 'react-feather';
import { MobileView, BrowserView } from 'react-device-detect';

import Logo from '../../images/Logo.jpg';
import {
  HOME_PATH,
  ABOUT_US_PATH,
  PORTFOLIO_PATH,
  CONTACTS_PATH,
  GALLERY_PATH,
  ORDER_CONFIRMATION
} from '../../constants/Routes';

import { HeaderContainer, LogoImage, Menu, MobileMenu } from './style';

const ORDER = typeof localStorage !== 'undefined' && localStorage.getItem('currentOrder');
const localCurrentOrder = JSON.parse(ORDER);

const Header = ({ siteTitle, hasBiggerHeader }) => (
  <div>
    <HeaderContainer>
      {hasBiggerHeader ? (
        <Grid center='xs'>
          <Row>
            <Col md={12}>
              <Link to={HOME_PATH}>
                <LogoImage
                  src={Logo}
                  style={{
                    width: 250,
                    height: 'auto',
                    margin: '0 auto',
                    display: 'block'
                  }}
                />
              </Link>

              <BrowserView>
                <Menu style={{ textAlign: 'center', marginTop: 30 }}>
                  <li>
                    <Link to={ABOUT_US_PATH}>Sobre nós</Link>
                  </li>

                  <li>
                    <Link to={GALLERY_PATH}>Galeria Privada</Link>
                  </li>

                  <li>
                    <Link to={PORTFOLIO_PATH}>Portfolio</Link>
                  </li>

                  <li>
                    <Link to={CONTACTS_PATH}>Contactos</Link>
                  </li>

                  {localCurrentOrder && localCurrentOrder.photos &&
                    <li>
                      <Link to={ORDER_CONFIRMATION}>
                        <ShoppingBag size={17} />
                      </Link>
                    </li>
                  }
                </Menu>
              </BrowserView>
            </Col>
          </Row>
        </Grid>
      ) : (
        <Grid>
          <Row center='xs' start='md'>
            <Col md={3} xs={12}>
              <Link to={HOME_PATH}>
                <LogoImage src={Logo} />
              </Link>
            </Col>

            <Col md={9}>
              <BrowserView>
                <Menu>
                  <li>
                    <Link to={ABOUT_US_PATH}>Sobre nós</Link>
                  </li>

                  <li>
                    <Link to={GALLERY_PATH}>Galeria Privada</Link>
                  </li>

                  <li>
                    <Link to={PORTFOLIO_PATH}>Portfolio</Link>
                  </li>

                  <li>
                    <Link to={CONTACTS_PATH}>Contactos</Link>
                  </li>

                  {localCurrentOrder && localCurrentOrder.photos &&
                    <li>
                      <Link to={ORDER_CONFIRMATION}>
                        <ShoppingBag size={17} />
                      </Link>
                    </li>
                  }
                </Menu>
              </BrowserView>
            </Col>
          </Row>
        </Grid>
      )}
    </HeaderContainer>

    <MobileView>
      <MobileMenu>
        <li>
          <Link to={HOME_PATH}>
            <Home />
          </Link>
        </li>

        <li>
          <Link to={ABOUT_US_PATH}>
            <Info />
          </Link>
        </li>

        <li>
          <Link to={GALLERY_PATH}>
            <Lock />
          </Link>
        </li>

        <li>
          <Link to={PORTFOLIO_PATH}>
            <Eye />
          </Link>
        </li>

        <li>
          <Link to={CONTACTS_PATH}>
            <Phone />
          </Link>
        </li>
      </MobileMenu>
    </MobileView>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
