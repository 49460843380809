export const KEYWORDS = [
  `fotografia`,
  `casamento`,
  `casamentos`,
  `batizado`,
  `batizados`,
  `batismo`,
  `recem nascido`,
  `sessao`,
  `foto`,
  `fotos`,
  `fotografo`,
  `portugal`,
  `portugal`
];
