import React from 'react';
import { node, bool } from 'prop-types';

import Header from '../Header';

import '../base.css';

const Layout = ({ children, bigHeader }) => (
  <div>
    <Header hasBiggerHeader={bigHeader} />
    {children}

    <div style={{
      fontSize: 13,
      color: '#ccc',
      textAlign: 'center',
      margin: 100
    }}>
      &copy; {new Date().getFullYear()} HDPhotographers
    </div>
  </div>
);

Layout.propTypes = {
  children: node.isRequired,
  bigHeader: bool
};

Layout.defaultProps = {
  bigHeader: false
}

export default Layout;
