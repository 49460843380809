import styled from 'styled-components';

export const HeaderContainer = styled.header`
  padding: 30px;
`;

export const LogoImage = styled.img`
  height: 90px;
`;

export const Menu = styled.ul`
  text-align: right;
  list-style: none;
  margin: 20px 0 0;

  li {
    display: inline-block;
    margin-left: 20px;
  }

  a {
    font-size: 14px;
    text-transform: uppercase;
    color: #999;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: #333;
    }

    svg {
      vertical-align: middle;
      margin-top: -4px;
    }
  }
`;

export const MobileMenu = styled.ul`
  position: fixed;
  bottom: 0;
  z-index: 999;
  background: rgba(49, 52, 63, .95);
  width: 100%;
  left: 0;
  margin: 0;

  li {
    display: inline-block;
    width: 20%;
    text-align: center;
    padding: 10px 0;
    margin: 0;

    svg {
      color: #fff;
    }
  }
`;
