/* eslint-disable import/first */
typeof window !== 'undefined' && require('intersection-observer');

import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { LazyImage } from 'react-lazy-images';

import Layout from '../Layout';
import SEO from '../seo';
import { getS3BaseUrl } from '../../utils/s3';
import Loader from '../../images/Loader.svg';
import { KEYWORDS } from '../../constants/Keywords';

import { PhotoContainer } from './style';

const ShowcasePage = ({
  totalItems,
  s3Path
}) => (
  <Layout>
    <SEO title={``} keywords={[...KEYWORDS]} />

    <Grid>

      {Array.apply(null, { length: totalItems }).map(Number.call, Number).map(k => {
        var S3_PATH = `${getS3BaseUrl()}${s3Path}${k + 1}.jpg`;

        return (
          <Row center="xs" key={k}>
            <Col md={8}>
              <PhotoContainer>
                <LazyImage
                  src={S3_PATH}
                  alt={`Showcase ${k + 1}`}
                  placeholder={({ imageProps, ref }) => (
                    <div style={{ padding: '80px 0' }} ref={ref}>
                      <Loader />
                    </div>
                  )}
                  actual={({ imageProps }) => <img {...imageProps} alt={imageProps.alt} />}
                />
              </PhotoContainer>
            </Col>
          </Row>
        )
      })}
    </Grid>
  </Layout>
);

export default ShowcasePage;
