import { ACCESS_KEY, SECRET_KEY } from './Amazon';

const BUCKET_NAME = 'hdphotographers';
const REGION = 'eu-west-2';
const API_VERSION = '2006-03-01';

export const BUCKET_URL = 'https://s3-eu-west-2.amazonaws.com/hdphotographers/';
export const S3_CONFIG = {
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY
  },
  apiVersion: API_VERSION,
  params: {
    Bucket: BUCKET_NAME
  }
};
